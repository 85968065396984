import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f945072"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("span", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCta('legal')))
    }, "Legal"),
    _createElementVNode("span", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openCta('centro')))
    }, "Centro de privacidad"),
    _createElementVNode("span", {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openCta('policia')))
    }, "Política de privacidad"),
    _createElementVNode("span", {
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openCta('cookies')))
    }, "Cookies"),
    _createElementVNode("span", {
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openCta('acerca')))
    }, "Acerca de los anuncios")
  ]))
}