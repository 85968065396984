import { defineComponent } from "vue";

export default defineComponent({
  name: "ShareBox",
  data() {
    return {
      shareText: '¡Comparte tu playlist!',
      shareLink: `${window.location.origin}/`,
    };
  },
  methods: {
    facebook() {
      const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.shareLink
      )}`;
      window.open(
        url,
        "facebook",
        `width=550,height=450,scrollbars=yes,toolbar=no,location=yes`
      );
    },
    whatsapp() {
      const url = `https://wa.me/?text=${encodeURIComponent(
        `${this.shareText} ${this.shareLink}`
      )}`;
      window.open(url);
    },
    twitter() {
      const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        `${this.shareText}`
      )}&url=${encodeURIComponent(this.shareLink)}`;
      window.open(
        url,
        "twitter",
        `width=550,height=450,scrollbars=yes,toolbar=no,location=yes`
      );
    },
    copyUrl() {
      navigator.clipboard.writeText(this.shareLink);
    },
  },
});
