
import { defineComponent, ref } from 'vue';
import artists from '@/assets/data/translations/playlists/artists.json';
import matches from '@/assets/data/translations/playlists/matches.json';
import FrameOverlay from '@/components/FrameOverlay.vue';
import Logos from '@/components/CollabLogos.vue';
import Footer from '@/components/Footer.vue';
import Button from '@/components/Button.vue';
import { gsap } from 'gsap';
import axios from "axios";
export default defineComponent({
  name: "Home",
  components: { Button, Logos, FrameOverlay, Footer },
  data() {
    return {
      screenX: 0,
      artists,
      matches,
      selected: {
        flow1: [''],
        flow2: ['']
      },
      flow: 1,
      spotify: {
        clientId: '54810e84cd344a66b5d947dd133168ab'
      },
    };
  },
  beforeMount() {
    window.addEventListener('wheel', this.handleScroll);
    window.addEventListener('drag', this.dragScroll);
    window.addEventListener('dragstart', this.dragStart);
    var params = this.$route.hash;
    if (params.includes("access_token")) {
      localStorage.setItem('userAuthInfo', params)
      this.$router.push(`Result`);
    }
  },
  created() {
  },
  methods: {
    handleScroll(event: any) {
      const scrollSpeed = 64;
      if (event.deltaY == '100') {
        const track = document.getElementById('swiper-track')
        if (track)
          track['scrollLeft'] += scrollSpeed;
      }
      else if (event.deltaY == '-100') {
        const track = document.getElementById('swiper-track')
        if (track)
          track['scrollLeft'] -= scrollSpeed;
      }
    },
    dragScroll(event: any) {
      //Hide ghost image during drag
      this.hideGhostImage(event)
      //Allow drag and drag track at certain speed
      const dragSpeed = 3;
      if (event.target.parentElement.parentElement.classList.contains("draggable")) {
        const track = document.getElementById('swiper-track')
        if (track) {
          if (this.screenX < event.screenX) {
            track['scrollLeft'] -= dragSpeed;
          } else {
            track['scrollLeft'] += dragSpeed;
          }
          this.screenX = event.screenX;
        }
      }
    },
    dragStart(event: any) {
      this.hideGhostImage(event)
    },
    hideGhostImage(event: any){
      var img = new Image();
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
      event.dataTransfer.setDragImage(img, 0, 0);
    },
    selectCover(coverName: string, flow: number) {
      //If selected again, set active to inactive
      if (this.selected.flow1.includes(coverName) ?? this.selected.flow2.includes(coverName)) {
        this.selected.flow1.includes(coverName) ? document.getElementById(coverName)?.classList.remove('selected') : document.getElementById(coverName)?.classList.remove('selected')
        this.selected.flow1.includes(coverName) ?
          this.selected.flow1 = this.selected.flow1.filter(function (value) { return value !== coverName }) :
          this.selected.flow2 = this.selected.flow2.filter(function (value) { return value !== coverName });
        return
      }

      if (flow == 1) {
        //Remove previous selected cover and reselect current
        document.getElementById(coverName)?.classList.add('selected')
        this.selected.flow1.push(coverName);
        gsap.fromTo(`.cover-image`, { webkitFilter: "brightness(100%)" }, { webkitFilter: "brightness(60%)", duration: 0.35 });
      } else {
        document.getElementById(coverName)?.classList.add('selected')
        this.selected.flow2.push(coverName);
        gsap.fromTo(`.cover-image`, { webkitFilter: "brightness(100%)" }, { webkitFilter: "brightness(60%)", duration: 0.35 });
      }
    },
    spotifyLogin() {
      //Store selected items
      const selectedArtists = this.selected.flow1.slice(1, this.selected.flow1.length)
      const selectedMatches = this.selected.flow2.slice(1, this.selected.flow2.length)
      localStorage.setItem('flow1', JSON.stringify(selectedArtists))
      localStorage.setItem('flow2', JSON.stringify(selectedMatches))

      //Redirect & Log into spotify
      const redirectUri = window.location.href;
      const scope = 'playlist-modify-public';
      window.location.href = `https://accounts.spotify.com/authorize?client_id=${this.spotify.clientId}&response_type=token&scope=${scope}&redirect_uri=${redirectUri}`;
    },
    async getAccount(token: string) {
      try {
        const response = await axios.get(`https://api.spotify.com/v1/me`, { headers: { Authorization: `Bearer ${token}`, }, });
        return response.data;
      } catch (error: any) {
        if (error.response) return error.response;
        else console.log("Error", error.message);
      }
    },
    previousFlow() {
      this.selected.flow1 = [''];
      this.selected.flow2 = [''];
      this.flow = 1;
    },
    nextFlow() {
      if (this.selected.flow1.length == 1 && this.flow == 1) return
      else if (this.selected.flow2.length == 1 && this.flow == 2) return

      gsap.fromTo(`.cover-image`, { webkitFilter: "brightness(60%)" }, { webkitFilter: "brightness(100%)", duration: 0.35 });

      if (this.flow >= 2) {
        this.spotifyLogin()
        return
      }

      this.flow++;
    },
  },
  computed: {
    nextActive() {
      if (this.flow == 1 && this.selected.flow1.length > 1) return true
      else if (this.flow == 2 && this.selected.flow2.length > 1) return true
      else return false
    }
  }
});
