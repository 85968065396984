import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  data() {
    return {
    };
  },
  methods: {
    openCta(type: string) {
      switch (type) {
        case 'legal':
          console.log(type);
          window.open('https://www.spotify.com/mx/legal/end-user-agreement/', '_blank');
          break;
        case 'centro':
          console.log(type);
          window.open('https://www.spotify.com/mx/privacy', '_blank');
          break;
        case 'policia':
          console.log(type);
          window.open('https://www.spotify.com/mx/legal/privacy-policy/', '_blank');
          break;
        case 'cookies':
          console.log(type);
          window.open('https://www.spotify.com/mx/legal/cookies-policy/', '_blank');
          break;
        case 'acerca':
          console.log(type);
          window.open('https://www.spotify.com/mx/legal/privacy-policy/#s3', '_blank');
          break;
        default:
          console.log(type);
          break;
      }
      window.open('./terms.pdf', '_blank');
    }
  },
});
