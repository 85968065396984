import { defineComponent } from "vue";

export default defineComponent({
  name: "CollabLogos",
  data() {
    return {
    };
  },
  methods: {
  },
});
