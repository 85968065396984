
import { defineComponent } from 'vue';
import FrameOverlay from '@/components/FrameOverlay.vue';
import Logos from '@/components/CollabLogos.vue';
import ShareBox from '@/components/ShareBox.vue';
import Footer from '@/components/Footer.vue';
import * as SpotifyHelper from "@/utils/spotify";
import artists from '@/assets/data/translations/playlists/artists.json';
import matches from '@/assets/data/translations/playlists/matches.json';

export default defineComponent({
  name: "Result",
  components: {
    Logos,
    FrameOverlay,
    ShareBox,
    Footer
  },
  data() {
    return {
      recommendedPlaylist: '',
      playlistName: '',
      playlistDescription: '',
      selectedMatch: [''],
      selectedArtist: [''],
      match: '',
      artists,
      matches
    };
  },
  mounted() {
    // setting of data
    const artists = JSON.parse(localStorage.getItem('flow2') || '');
    const matches = JSON.parse(localStorage.getItem('flow1') || '');
    const userAuthInfo = localStorage.getItem('userAuthInfo')
    for (const [key, value] of Object.entries(artists)) { this.selectedArtist.push(value as string) }
    for (const [key, value] of Object.entries(matches)) { this.selectedMatch.push(value as string) }
    this.selectedArtist = this.selectedArtist.slice(1, this.selectedArtist.length);
    this.selectedMatch = this.selectedMatch.slice(1, this.selectedMatch.length);

    // check for required data
    if (artists && matches && userAuthInfo)
      this.generatePlaylist()
    else
      this.$router.push(`/`);
  },
  methods: {
    async generatePlaylist() {
      // get user data and set playlist data
      const userAuthInfo = localStorage.getItem('userAuthInfo')
      if (userAuthInfo) {
        var tokenString = userAuthInfo.substring(1)
        var token = tokenString.split('=')[1].split('&')[0]
        const getAccount = await SpotifyHelper.getAccount(token);
        this.playlistName = getAccount.display_name ? `${getAccount.display_name.replace(/[^a-zA-Z0-9]/g, '')} X Red Bull Batalla` : `Red Bull Batalla`;
        const createdPlaylist = await SpotifyHelper.createPlaylist(token, getAccount, this.playlistName, this.playlistDescription)
        await this.addPlaylistItems(token, createdPlaylist)
        this.recommendedPlaylist = `https://open.spotify.com/embed/playlist/${createdPlaylist}?utm_source=generator&theme=0`;
      }
    },
    async addPlaylistItems(token: string, createdPlaylist: string) {
      // get all required tracks for the personal plalist
      const getArtistTracks = await this.getArtistTracks(token)
      const getMatchTracks = await this.getMatchTracks(token)

      const tracks = getArtistTracks.concat(getMatchTracks);

      // add all tracks into playlist
      await SpotifyHelper.addTracksToPlaylist(token, createdPlaylist, tracks)
    },
    async getArtistTracks(token: string) {
      let tracks = [];
      let playlistLength = 0;
      for (const [key, value] of Object.entries(this.selectedArtist)) {
        const selectedArtist = value;
        const artistPlaylists = artists.filter(function (artist) { return artist.imageName == selectedArtist });
        const selectedArtistsPlaylist = artistPlaylists[0].playlist;
        const getArtistPlaylist = await SpotifyHelper.getAlbum(selectedArtistsPlaylist, token)
        playlistLength = getArtistPlaylist.tracks.items.length;
        for (let songIndex = 0; songIndex < playlistLength; songIndex++) {
          tracks.push(getArtistPlaylist.tracks.items[songIndex].uri);
        }
      }
      return tracks
    },
    async getMatchTracks(token: string) {
      let tracks = [];
      let playlistLength = 0;
      for (const [key, value] of Object.entries(this.selectedMatch)) {
        const selectedMatch = value;
        const matchPlaylists = matches.filter(function (artist) { return artist.imageName == selectedMatch });
        const selectedMatchPlaylist = matchPlaylists[0].playlist;
        const getMatchPlaylist = await SpotifyHelper.getAlbum(selectedMatchPlaylist, token)
        playlistLength = getMatchPlaylist.tracks.items.length;
        for (let songIndex = 0; songIndex < playlistLength; songIndex++) {
          tracks.push(getMatchPlaylist.tracks.items[songIndex].uri);
        }
      }
      return tracks
    }
  }
});
