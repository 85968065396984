import { defineComponent } from "vue";

export default defineComponent({
  name: "Button",
  props: {
    isActive: Boolean,
    buttonText: String,
    theme: String
  },
  data() {
    return {
    };
  },
  methods: {
  },
});
